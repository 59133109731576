<template>
  <div class="infoPage">
      <div class="right">
        <div class="r_item" v-for="item of datalist" :key="item.dynamicinfomationId" @click="go(item)">
          <div class="img scale">
<!--            <img :src="item.imagespath" alt="">-->
            <el-image
                style="width: 100%; height: 100%"
                :src="item.dynamicinfomationCover"
                fit="cover"></el-image>
            <div class="eye el-icon-view">&nbsp;{{ item.totalVisitNum }}</div>
          </div>
          <div class="content">
            <div class="text_left">
              <div class="text1">
                <div class="tTitle">{{item.webTitle}}</div>
                <div class="ziti">{{item.dynamicinfomationCreateTime}}</div>
              </div>
              <div class="text2"><span v-for="(items,indexs) in item.tags">{{items}}</span></div>
<!--              <div class="text3"><span>#{{item.type}}</span>&nbsp;{{item.webDesc}}</div>-->
              <div class="text3">&nbsp;{{item.webDesc}}</div>
            </div>
<!--            <div class="text_right">-->
<!--              <div class="text1">{{item.time}}</div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
<!--      <div class="paging-device">-->
<!--        <el-pagination-->
<!--            layout="prev, pager, next"-->
<!--            @size-change="handleSizeChange"-->
<!--            @current-change="handleCurrentChange"-->
<!--            :current-page="current_page"-->
<!--            :page-size="per_page"-->
<!--            :total="totalCount">-->
<!--        </el-pagination>-->
<!--      </div>-->
  </div>
</template>

<script>
import {imgURL,dynamicinfomationList} from "@/api";

export default {
  name: "youhui",
  data(){
    return {
      imgURL,
      url:'',
      totalCount: 0,
      current_page: 1,
      per_page: 4,
      datalist:[
        // {
        //   imagespath:'http://81.68.87.225:9809/static/img/swiperPage/gue01.png',
        //   title:'遏制福寿螺蔓延 保护古运河生态',
        //   time:'2022/05/25',
        //   info:'近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺',
        //   type:'#清名桥历史文化街区'
        // }
      ],
    }
  },
  mounted() {
    this.createlist()
  },
  methods:{
    // 每页条数
    handleSizeChange(val) {
      this.per_page=val
      this.createlist()
    },
    // 页数
    handleCurrentChange(val) {
      this.current_page=val
      this.createlist()
    },
    createlist(){
      dynamicinfomationList({
        // pageNum:this.current_page,
        // pageSize:this.per_page
        dynamicinfomationType:35
      })
          .then(res=>{
            console.log(res)
            if(res.code==200){
              this.datalist=res.rows
              this.datalist.forEach((item,index)=>{
                if(item.tags && item.tags.trim().length>0){item.tags = item.tags.split(',')}
                // item.tags = item.tags.split(',')
              })
            //   // this.datalist[0].type = '通知公告'
            //   // this.datalist[1].type = '优惠活动'
            //   // this.datalist[2].type = '节庆活动'
            //   // this.datalist[3].type = '通知公告'
            //   // this.totalCount=res.total
            }
          })
    },
    go(item){
      this.$router.push({path:'informationInfo',query:{id:item.dynamicinfomationId,type:'35'}})
    },
    select(){

    },
    changep(){

    }
  }
}
</script>

<style scoped lang="less">
.ziti{
  font-size: 14px;
  font-family: STHeitiSC-Medium, STHeitiSC;
  font-weight: 600;
  color: #333333;
}
.infoPage{
  width: 100%;
  overflow: hidden;
  //height: calc(100vh - 130px);
  //background: skyblue;
  //padding: 0 0 0 55px;
  .right{
    width: 1200px;
    margin:50px auto 120px auto;
    //margin-bottom: 120px;

    //height: 800px;
    //overflow: scroll;
    ////background: skyblue;
    //&::-webkit-scrollbar {
    //  width: 0px;
    //}
    .r_item{
      height: 200px;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 30px;
      &:hover{
        cursor:pointer;
        .content .text_left .text1{
          color: #337D8D;
        }
        //.img img{
        //transform: scale(1.1);
        //}
      }
      &:nth-last-child(1){
        margin-bottom: 0;
      }
      .img{
        width: 380px;
        height: 200px;
        margin-right: 40px;
        position: relative;
        img{
          width: 100%;
          height: 100%;
          //transition:all 500ms ease;
          //transform: scale(1);
        }
        .eye{
          position: absolute;
          top: 16px;
          left: 16px;
          padding: 8px 12px;
          background: #337D8D55;
          border-radius: 5px;
          font-size: 14px;
          font-family: STHeitiSC-Medium, STHeitiSC;
          font-weight: 600;
          color: #ffffff;
        }
      }
      .content{
        width: 1100px;
        height: 160px;
        display: flex;
        justify-content: space-between;
        .text_left{
          width: 830px;
          .text1{
            font-size: 24px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 600;
            color: #333333;
            line-height: 33px;
            display: flex;
            justify-content: space-between;
          }
          .text2{
            margin: 14px 0 12px;
            span{
              font-size: 12px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #333333;
              padding: 3px 13px 4px 12px;
              margin-left: 10px;
              background: rgba(120, 120, 120, 0.1);
              border-radius: 17px;
              &:nth-child(1){
                margin-left: 0;
              }
            }
          }
          .text3{
            font-size: 14px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 400;
            color: #999999;
            line-height: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /*截取第三行*/
            overflow: hidden;
            span{
              font-size: 14px;
              font-family: STSongti-SC-Black, STSongti-SC;
              font-weight: 400;
              color: #007B8A;
            }
          }
        }
        .text_right{
          width: 180px;
          cursor: pointer;
          text-align: right;
          padding-right: 40px;
          box-sizing: border-box;
          .text1{
            font-size: 14px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 600;
            color: #333333;
            line-height: 33px;
            margin: 0 0 45px 0;
            span{
              margin-right: 20px;
              color: #999999;
            }
          }
          .text2{
            font-size: 14px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 400;
            color: #337D8D;
            line-height: 22px;
            text-align: right;
            display: flex;
            justify-content: end;
            align-items: center;
            img{
              width: 30px;
              height: 30px;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
  /deep/.paging-device{
    margin: 70px 0 70px -50px;
    display: flex;
    justify-content: center;
    .el-pager li{
      height: 35px;
      line-height: 35px;
    }
    .btn-prev{
      border-radius: 50%;
      box-shadow: 0px 3px 15px 2px rgba(235, 235, 235, 0.5);
      padding: 0;
      display: initial;
      width: 35px;
      height: 35px;
      margin-right: 40px;
      i{
        font-size: 16px;
      }
    }
    .btn-next{
      border-radius: 50%;
      box-shadow: 0px 3px 15px 2px rgba(235, 235, 235, 0.5);
      padding: 0;
      display: initial;
      width: 35px;
      height: 35px;
      margin-left: 40px;
      i{
        font-size: 16px;
      }
    }
  }
}
</style>
